import { useEffect, useState } from "react";
import { Input, Button, Typography, Divider, message } from "antd";
import classes from "../styles/login.module.css";
import Footer from "../components/Footer";
import { FlabsLogo } from "../asssets";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import CountryPhoneInput from "antd-country-phone-input";
import Loader from "../components/Loader/Loader";

const { Text } = Typography;
// import axios from "axios";
const Login = () => {
  const { labID } = useParams();
  // const [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();
  // const [textError, setTextError] = useState(false);
  const [number, setNumber] = useState({ code: 91 });
  const [customLoginLogo, setCustomLoginLogo] = useState("");
  const [customLoginLogoHeight, setCustomLoginLogoHeight] = useState("");
  const [customLoginLoading, setCustomLoginLoading] = useState(true);

  useEffect(() => {
    getCountryCode();
    if (labID) {
      fetchCustomLogin(labID);
    } else {
      setCustomLoginLoading(false);
    }
  }, []);

  const removeAllCustomLoginStates = () => {
    localStorage.removeItem("isCustomLogin");
    localStorage.removeItem("customLoginLabID");
    localStorage.removeItem("customLoginLogo");
    localStorage.removeItem("customLoginLogoHeight");
  };

  const fetchCustomLogin = async (labID) => {
    try {
      const res = await axios.get(`/customLogin/get?lab=${labID}`);
      setCustomLoginLoading(false);
      if (res.status === 200 && res?.data?.isPatientLogin) {
        setCustomLoginLogo(res.data.logo);
        setCustomLoginLogoHeight(res.data.logoHeight);
        localStorage.setItem("isCustomLogin", true);
        localStorage.setItem("customLoginLabID", labID);
        localStorage.setItem("customLoginLogo", res.data.logo);
        localStorage.setItem("customLoginLogoHeight", res.data.logoHeight);
      } else {
        navigate("/");
        removeAllCustomLoginStates();
      }
    } catch (err) {
      setCustomLoginLoading(false);
      navigate("/");
      removeAllCustomLoginStates();
    }
  };

  const submitHandler = () => {
    axios
      .post("/patient/login", { contact: number.phone, code: number.code })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          navigate(`/otp/${res.data.token}/${number.code}`);
        } else {
          message.error("Something went wrong. Please try again later.");
        }
        return;
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 404) {
          message.error("User Not Found");
        } else {
          message.error("Something went wrong. Please try again later.");
        }
        return;
      });
    // navigate("/otp");
  };

  const enterLogin = (event) => {
    if (event.keyCode === 13) {
      submitHandler();
    }
  };

  const getCountryCode = async () => {
    try {
      const res = await axios.get("/getCountryCode");
      const code = res?.data.countryCode;
      setNumber({ code: parseInt(code) });
    } catch (error) {
      console.log(error);
    }
  };

  if (customLoginLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className={classes["main-container"]}>
        {/* <div className={classes["background-img1"]}></div>
        <div className={classes["background-img2"]}></div> */}
        <div className={classes["flabs-logo"]}>
          {customLoginLogo ? (
            <img
              src={customLoginLogo}
              alt="logo"
              style={{
                height: `${
                  customLoginLogoHeight ? customLoginLogoHeight : 35
                }px`,
              }}
            />
          ) : (
            <FlabsLogo className={classes["flabs-logo-size"]} />
          )}
        </div>
        <div className={classes["login-background"]}>
          <div className={classes["login-container"]}>
            <div className={classes["inner-content"]}>
              <Text style={{ fontSize: "16px", fontWeight: "450" }}>
                Patient Log In
              </Text>
              <Divider style={{ marginTop: "12px" }} />
              <div className={classes["input-bar-div"]}>
                <CountryPhoneInput
                  inline
                  type="number"
                  size="large"
                  className="country-input"
                  placeholder="Enter phone number"
                  value={number}
                  onChange={(v) => {
                    if (number.code === 91 && v.phone > 9999999999) return;
                    setNumber(v);
                  }}
                  required
                  onKeyDown={(e) => enterLogin(e)}
                />
              </div>

              <div>
                <Button type="primary" size="large" onClick={submitHandler}>
                  Get OTP
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Login;
